<!--
 * @Descripttion:
 * @version:
 * @Author: 高涛
 * @Date: 2021-10-25 15:19:14
 * @LastEditors: 高涛
 * @LastEditTime: 2021-12-28 14:38:00
-->
<template>
  <a-form-model
    ref="ruleForm"
    :model="form"
    :rules="rules"
    :label-col="labelCol"
    :wrapper-col="wrapperCol">
        <br/>
        <!-- 类型名称 -->
        <a-form-model-item label="特性名称" prop="propertyName">
            <a-input placeholder="请输入" v-model="form.propertyName"/>
        </a-form-model-item>
        <!-- 类型编号 -->
        <a-form-model-item label="特性编号" prop="propertyCode">
            <a-input placeholder="请输入" :disabled="form.id!==''" v-model="form.propertyCode"/>
        </a-form-model-item>
        <a-form-model-item label="特性分类" prop="propertyTypeCode">
          <a-select v-model="form.propertyTypeCode" default-value="sell"  @change="handleChange">
            <a-select-option v-for="item in propertyTypeGroupList" :key="item.id" :value="item.code">
              {{item.name}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <!-- 栏目描述 -->
        <a-form-item label="备注">
            <a-textarea v-model="form.memo" placeholder="请输入备注" :rows="4"/>
        </a-form-item>
        <!-- 底部按钮 -->
        <a-form-item :wrapper-col="{ span: 14, offset: 6 }">
            <a-button type="primary" @click.prevent="onSubmit">提交</a-button>
            <a-button style="margin-left: 10px" @click="resetFields">取消</a-button>
        </a-form-item>
        <br/>
    </a-form-model>
</template>

<script>
export default {
  data () {
    return {
      propertyTypeGroupList: [],
      iseditorAdd: '',
      value: undefined,
      treeData: [],
      options: [
        {
          value: 'jack',
          label: 'Jack'
        },
        {
          value: 'lucy',
          label: 'Lucy'
        }
      ],
      rules: {
        name: [
          { required: true, message: '请填写栏目名称', trigger: 'blur' }
        ],
        sort: [
          { required: true, message: '请填写排序', trigger: 'blur' }
        ]
      },
      form: {
        memo: '',
        propertyCode: '',
        propertyName: '',
        propertyTypeCode: 'sell',
        propertyTypeName: '销售',
        sort: '',
        id: ''
      },
      labelCol: {
        span: 6
      },
      wrapperCol: {
        span: 14
      }
    }
  },
  watch: {
    value (value) {
      console.log(value)
    }
  },
  created () {
    // this.queryTreeData()
  },
  methods: {
    resetForm () {
      this.form = {
        memo: '',
        propertyCode: '',
        propertyName: '',
        propertyTypeCode: 'sell',
        propertyTypeName: '销售',
        sort: '',
        id: ''
      }
    },
    async init ({ editorAdd, id, parentId }) {
      // this.queryTreeData()
      this.getPropertyGroup()
      this.resetForm()
      this.iseditorAdd = editorAdd || ''
      if (editorAdd === 'add') {
        this.form.id = ''
      } else if (editorAdd === 'edit') {
        const res = await this.$store.dispatch('http', {
          api: 'businessPropertiesInfo',
          query: { id: id }
        })
        console.log(res)
        if (res) {
          this.form = { ...res }
        }
      }
    },
    async getPropertyGroup () {
      const res = await this.$store.dispatch('http', {
        api: 'propertyTypeGroup'
      })
      console.log(res, 'res propertyTypeGroup')
      if (res) {
        this.propertyTypeGroupList = res
      }
    },
    onSubmit () {
      // this.submitForm()
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.submitForm()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    async submitForm () {
      let apiStr = ''
      apiStr = this.iseditorAdd === 'add' ? 'businessPropertiesAdd' : this.iseditorAdd === 'edit' ? 'businessPropertiesEdit' : ''
      console.log(apiStr)
      const res = await this.$store.dispatch('http', {
        api: apiStr,
        params: this.form
      })
      console.log(res)
      if (res) {
        this.$store.dispatch('showToast', { type: 'success', message: '操作成功' })
        this.$emit('ok')
      }
    },
    handleChange (e) {
      this.propertyTypeGroupList.forEach(element => {
        if (element.code === e) {
          this.form.propertyTypeName = element.name
        }
      })
    },
    resetFields () {
      console.log('resetFields')
      this.$emit('ok')
    }
  }
}
</script>

<style>

</style>
